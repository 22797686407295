import http from '@/utils/request.js'

// 新闻列表
export function newsList(data) {
  return http({
    url: '/news/list',
    method: 'get',
    params: data
  })
}
// 新闻列表---预览
export function newsListPre(data) {
  return http({
    url: '/news/preList',
    method: 'get',
    params: data
  })
}

// 新闻详情
export function newsDetail(data) {
  return http({
    url: '/news/getNewsInfo',
    method: 'post',
    data
  })
}
// 新闻详情---预览
export function newsDetailPre(data) {
  return http({
    url: '/news/getPreNewsInfo',
    method: 'get',
    params: data
  })
}