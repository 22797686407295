<template>
  <div class="news" id="news">
    <!-- <div class="crumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs_a">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }"
          >新闻公告</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/news' }"
          >公司新闻</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div> -->
    <crumbs :item="title"/>
    <div class="main">
      <div
          class="box"
          ref="news"
          v-loading="loading"
          element-loading-text="拼命加载中"
      >
        <!-- 搜索 -->
        <!--        <div class="search">-->
        <!--          <el-select-->
        <!--            v-model="value"-->
        <!--            @change="change"-->
        <!--            popper-class="popper-select"-->
        <!--            :popper-append-to-body="false"-->
        <!--            filterable-->
        <!--          >-->
        <!--            <el-option-->
        <!--              v-for="item in options"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--            >-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--          <img @click="submit" src="~@/static/images/search.png" alt="" />-->
        <!--        </div>-->
        <div class="search">
          <el-input
              clearable
              v-model="value"
              placeholder="输入关键字搜索"
          ></el-input>
          <img @click="submit" src="~@/static/images/search.png" alt=""/>
        </div>
        <!-- 新闻详情 -->
        <!-- <<<<<<< HEAD
        <div class="details" v-for="item in newDetails" :key="item.title">
          <img :src="item.img" alt="" />
          <div class="details_text" @click="details('news/newsDetails')">
            <p>{{ item.time }}</p>
            <div class="details_title">{{ item.title }}</div>
======= -->
        <div
            class="details"
            v-for="item in newDetails"
            :key="item.title"
            @click="details(item.zid)"
        >
          <img :src="item.newsImage ? item.newsImage : img" alt=""/>
          <div class="details_text">
            <p>{{ item.newsDate }}</p>
            <div class="details_title">{{ item.newsTitle }}</div>
            <div class="details_wen">
              {{ item.newsSummary }}
            </div>
          </div>
        </div>
        <!--        <Paging :total="total" :pageNum="pageNum" :pages="pages"></Paging>-->
        <div class="paging" v-if="show">
          <p>共有{{ total }}条记录</p>
          <div>
            <img
                @click="getPageNumAReduction()"
                src="~@/static/images/l_arrow@2x.png"
                alt=""
            />
            <span>{{ pageNum }}/{{ pages }}</span>
            <img
                @click="getPageNumAdd()"
                src="~@/static/images/ic_arrow_black@2x.png"
                alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
import {newsListPre} from "@/api/news";

export default {
  name: "news",

  data() {
    return {
      title: [],
      list: {
        AZ01501: "公司新闻",
        AZ01502: "客服公告",
        AZ01503: "公司公告",
        AZ01504: "招标公告",
      },
      toTitle: "",
      loading: false,
      newDetails: [],
      value: "",
      total: 0,
      pageNum: 1,
      pages: 0,
      img: require("@/static/images/img_news_default@2x.png"),
      showZid: null, //需要显示的产品zid
      show: true,
    };
  },
  components: {crumbs},
  filters: {
    timeFilter(date) {
      const time = new Date(date);
      return `${time.getMonth()}/${time.getDate()}, ${time.getFullYear()}`;
    },
  },
  created() {
    console.log(this.$route.query);
    this.showZid = this.$route.query.zid;
    this.show = this.$route.query.zid ? false : true;
    if (sessionStorage.getItem("news")) {
      //如果有就读取缓存里面的数据
      this.pageNum = sessionStorage.getItem("currentPage");
      // let top = sessionStorage.getItem("Top");
      sessionStorage.removeItem("news");
    } else {
      this.pageNum = 1;
      //这个主要是从其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem("currentPage");
    }
    console.log(this.pageNum);
    this.request(this.pageNum);
  },
  mounted() {
  },
  watch: {
    pageNum(val, value) {
      console.log("1", val, value);
      this.pageNum = val;
    },
  },
  methods: {
    submit() {
      console.log(this.value);
      this.request(this.pageNum, this.value);
    },
    details(zid) {
      sessionStorage.setItem("currentPage", this.pageNum);
      sessionStorage.setItem("NEWS_TITLE", this.toTitle);
      // sessionStorage.setItem("Top", e.pageY);
      this.$router.push(`/preview/details?zid=${zid}`);
    },
    change(e) {
      console.log(e);
    },
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.pageNum);
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.pageNum);
      }
    },

    async request(num, title) {
      this.loading = true;
      // this.fullscreenLoading = true;
      this.toTitle = this.$route.params.id;
      console.log("2323", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await newsListPre({
          timeStamp,
          sign,
          modelType: "pre",
          pageNum: num,
          newsType: this.$route.params.id,
          newsTitle: title,
        });
        console.log("新闻列表", res.data);
        if (res.data.code == 200) {
          this.total = res.data.total;
          this.data = res.data.rows.findIndex(
              (item) => item.zid == this.showZid
          );
          // this.newDetails = res.data.rows;
          this.showZid
              ? this.newDetails.push(res.data.rows[this.data])
              : (this.newDetails = res.data.rows);
          console.log(this.newDetails)
          if (!this.newDetails[0]) {
            this.$router.replace("/found404");
          }
          this.pages = res.data.pages;
          console.log(111)
          this.pageNum = res.data.pageNum;
          this.title = [
            {
              title: "新闻公告",
              link: "",
            },
            {
              title: `${this.list[this.toTitle]}`,
              link: ``,
            },
          ];
        }
        if (
            res.data.code == 401 ||
            res.data.code == 404 ||
            res.data.code == 500 ||
            res.data.code == 606
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
      // this.fullscreenLoading = false;
      this.loading = false;
    },
  },
  // beforeDestroy() {
  //   sessionStorage.removeItem("news");
  // },
  destroyed() {
    sessionStorage.removeItem("news");
  },
};
</script>

<style lang="less" scoped>
.news {
  .crumbs {
    background: #003781;
  }

  .main {
    width: 65vw;
    margin: auto;

    .box {
      margin: 4rem 0;
      opacity: .9;

      > .search {
        display: flex;
        align-items: center;
        justify-content: end;

        /deep/ .el-input__inner {
          border: none !important;
          width: 22rem !important;
          border-bottom: 1px solid !important;
          border-radius: 0 !important;
          font-size: 2rem;
          height: auto;
        }

        /deep/ .el-input {
          width: auto;
        }

        > img {
          width: 4rem;
          height: 4rem;
          margin-left: 1.5rem;
          cursor: pointer;
        }
      }

      > .details {
        display: flex;
        justify-content: space-between;
        margin: 4rem 0;
        cursor: pointer;

        .details_text {
          width: 71%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          > p {
            font-size: 2.5rem;
          }

          > .details_title {
            font-size: 2.65rem;
            margin: 1.5rem 0;
            font-weight: 700;
          }

          > .details_wen {
            font-size: 1.5rem;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }

        > img {
          // width: 250px;
          width: 25%;
          height: 23rem;
        }
      }

      ::v-deep .paging {
        width: 100%;
        margin: 10rem 0 6rem;
      }
    }
  }
}

::v-deep.el-breadcrumb {
  padding: 1.5rem 0;
  width: 80vw;
  margin: auto;
  font-size: 1.5rem;

  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #409eff !important;
    cursor: pointer;
  }
}

::v-deep.el-breadcrumb__item .el-breadcrumb__inner,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a:hover,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
}

::v-deep .popper-select {
  position: absolute !important;
  top: 35px !important;
  left: 0px !important;
}
</style>
